import * as React from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router';
import { useLazyQuery, useQuery } from '@apollo/client';

import NoMatch from '../404Old';
import MeglerOverview from './MeglerOverview';
import { Seo } from '../../../utils/seo/Index';
import {
  EmployeeQuery,
  EmployeeQueryVariables,
  McvProfileDraftQuery,
  McvProfileDraftQueryVariables
} from '../../../__types__/generated/cluster';
import { BROKER_CV_DRAFT_QUERY } from '../../../../client/__graphql__/cluster/queries/profile/brokerCvDraft';
import { EMPLOYEE_QUERY } from '../../../../client/__graphql__/cluster/queries/employee/employee';
import { ENV_CONFIG } from '../../../__config__/env';

interface IRoute {
  path: string;
}

const MeglerDraft: React.FC<RouteComponentProps<IRoute>> = ({
  match,
  location,
  history
}) => {
  const [isLoaded, setIsLoaded] = React.useState(false);

  const { data, loading, error } = useQuery<
    McvProfileDraftQuery,
    McvProfileDraftQueryVariables
  >(BROKER_CV_DRAFT_QUERY, {
    fetchPolicy: 'network-only',
    skip: !match?.params?.path,
    variables: {
      input: { brandId: ENV_CONFIG?.BRAND_ID, profileId: match?.params?.path }
    },
    onError: ({ graphQLErrors }) => {
      setIsLoaded(true);
    },
    onCompleted: ({ mcvProfileDraft }) => {
      const { employeeAlias } = mcvProfileDraft?.profile ?? {};
      if (employeeAlias) {
        getEmployee({
          variables: {
            input: {
              brandId: ENV_CONFIG?.BRAND_ID,
              employeeAlias: employeeAlias
            }
          }
        });
      } else {
        setIsLoaded(true);
      }
    }
  });

  const [getEmployee, { data: edata, loading: eloading }] = useLazyQuery<
    EmployeeQuery,
    EmployeeQueryVariables
  >(EMPLOYEE_QUERY, {
    fetchPolicy: 'network-only',
    onError: () => {
      setIsLoaded(true);
    },
    onCompleted: ({ employee }) => {
      setIsLoaded(true);
    }
  });

  if (
    isLoaded &&
    !(data?.mcvProfileDraft?.profile?._id && edata?.employee?.employeeAlias)
  ) {
    return <NoMatch />;
  }

  return (
    <>
      {!eloading && edata?.employee?.name ? (
        <Seo
          title={`${edata?.employee?.name} - PrivatMegleren ${
            edata?.employee?.departments?.find((e) => e?.name)?.name ?? ``
          }`}
          description={edata?.employee?.title || ''}
          image={
            data?.mcvProfileDraft?.profile?.profileImageUrl ||
            edata?.employee?.image?.url ||
            ''
          }
          url={location.pathname}
          noIndexActivated={true}
          canonical={`https://privatmegleren.no${location.pathname}`}
        />
      ) : null}
      <MeglerOverview
        profile={data?.mcvProfileDraft?.profile ?? undefined}
        employee={edata?.employee ?? undefined}
      />
    </>
  );
};

export default MeglerDraft;

const Loader = styled.div`
  width: 100%;
  height: 100vh;
`;
