import { gql } from '@apollo/client';

export const BROKER_CV_DRAFT_QUERY = gql`
  query mcvProfileDraft($input: McvProfileDraftInput!) {
    mcvProfileDraft(input: $input) {
      profile {
        _id
        video {
          type
          url
          videoId
        }
        active
        name
        email
        employeeAlias
        profileImageUrl
        bkgImg {
          _id
          name
          url
        }
        profile {
          _id
          text
          title
        }
        work {
          _id
          text
          title
        }
        leisure {
          _id
          text
          title
        }
        myPartners {
          _id
          text
          title
        }
        quiz {
          quizId
          quiestionId
          question
          answer
        }
        feedback {
          _id
          customer
          text
        }
        exp {
          _id
          fromYear
          toYear
          text
          type
          location
        }
      }
    }
  }
`;
